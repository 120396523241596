import { useEffect, useState } from "react";
import sanityClient from "../../sanity";

import "./index.css";

export const Footer = () => {
  const [data, setData] = useState();

  useEffect(() => {
    sanityClient
      .fetch('*[_type == "footer"][0]')
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
  }, []);

  console.log(data);

  return (
    <footer>{data && data.copyrightText && <p>{data.copyrightText}</p>}</footer>
  );
};
