import { lazy, Suspense } from "react";

const Scene = lazy(() => import("../../Components/Scene"));
const Projects = lazy(() => import("../../Components/Projects"));

export const Home = () => {
  return (
    <>
      <Suspense fallback={null}>
        <Scene />
        <Projects />
      </Suspense>
    </>
  );
};
