import { useEffect, useState } from "react";
import sanityClient from "../../sanity";

import "./index.css";

export const UnderConstruction = () => {
  const [data, setData] = useState();

  useEffect(() => {
    sanityClient
      .fetch('*[_type == "underConstruction"][0]')
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
  }, []);

  console.log(data);

  return (
    <div className="coming-soon">
      <h1>{data && data.headingText ? data.headingText : "ALEX CIRKA"}</h1>
      <h3>
        {data && data.subHeadingText
          ? data.subHeadingText
          : "Under Construction"}
      </h3>
    </div>
  );
};
