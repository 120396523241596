import React, { useState, useEffect } from "react";
import sanityClient from "../../sanity";
import imageUrlBuilder from "@sanity/image-url";
import "./index.css";

export const ImageTrail = () => {
  const [imageData, setImageData] = useState([]);
  const [width] = useState(window.innerWidth);
  const [gridActive, setGridActive] = useState(false);

  const builder = imageUrlBuilder(sanityClient);

  const urlFor = (source) => {
    return builder.image(source);
  };

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "archive"][0]`)
      .then((data) => {
        setImageData(data.imageSet);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let images;

  let globalIndex = 0;
  let lastIndex = { x: 0, y: 0 };
  let effectCount = 6;

  const activate = (image, x, y) => {
    if (image) {
      image.style.left = `${x}px`;
      image.style.top = `${y}px`;
      image.dataset.status = "active";

      lastIndex = { x, y };
    }
  };

  const distFromLast = (x, y) => {
    return Math.hypot(x - lastIndex.x, y - lastIndex.y);
  };

  const handleMouseMove = (x, y) => {
    images = document.querySelectorAll(".image");

    if (images.length === 0) return;

    if (distFromLast(x, y) > 100) {
      const lead = images[globalIndex % images.length];
      const last = images[(globalIndex - effectCount) % images.length];

      activate(lead, x, y);

      if (lead) activate(lead, x, y);
      if (last) last.dataset.status = "inactive";

      globalIndex++;
    }
  };

  window.onmousemove = (e) => {
    if (!gridActive) handleMouseMove(e.clientX, e.clientY);
  };

  window.ontouchmove = (e) => {
    const touch = e.touches[0];
    handleMouseMove(touch.clientX, touch.clientY);
  };

  document.onmousedown = () => {
    setGridActive(true);
  };

  useEffect(() => {
    if (width <= 768) {
      setGridActive(true);
    } else {
      setGridActive(false);
    }
  }, [width]);
  return (
    <>
      <div
        className={`image-trail-container ${gridActive ? "grid-display" : ""}`}
      >
        {imageData &&
          imageData.length > 0 &&
          imageData.map((image, index) => {
            return (
              <img
                key={index}
                className="image"
                data-index={index}
                data-status="inactive"
                src={urlFor(image.asset._ref)}
                alt={`Image ${index}`}
                style={{
                  position: `${gridActive ? "unset" : "absolute"}`,
                  objectFit: `${gridActive ? "contain" : "cover"}`,
                  minWidth: `${gridActive ? "300px" : ""}`,
                  minHeight: `${gridActive ? "300px" : ""}`,
                  maxWidth: `${gridActive ? "500px" : ""}`,
                  maxHeight: `${gridActive ? "500px" : ""}`,
                }}
              />
            );
          })}
      </div>
    </>
  );
};
