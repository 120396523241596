import React from "react";
import { ImageTrail } from "../../Components/ImageTrail";

import "./index.css";

const Archive = () => {
  return (
    <>
      <section className="archive section">
        <ImageTrail />
      </section>
    </>
  );
};

export default Archive;
