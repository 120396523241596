import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ContextProvider } from "./utils/context";
import sanityClient from "./sanity";
import Archive from "./Routes/Archive";
import { Home } from "./Routes/Home";

import "./root.css";
import Header from "./Components/Header";
import About from "./Components/About";
import { UnderConstruction } from "./Components/UnderConstruction";
import { Footer } from "./Components/Footer";

const App = () => {
  const [data, setData] = useState([]);
  const [showUnderConstruction, setShowUnderConstruction] = useState(false);

  useEffect(() => {
    sanityClient
      .fetch('*[_type == "baseStyling"][0]')
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    sanityClient
      .fetch('*[_type == "underConstruction"][0]')
      .then((result) => {
        setShowUnderConstruction(result.toggle);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const baseStyle = {
    height: data.bodyHeight || "100%",
    width: data.bodyWidth || "100vw",
  };
  const backgroundStyle = {};

  if (data.backgroundType === "colourBackground") {
    backgroundStyle.backgroundColor = data.colourBackgroundInput || "#fff";
  } else if (data.backgroundType === "imageBackground") {
    backgroundStyle.backgroundImage = data.imageBackgroundUpload;
  }

  const mainStyle = { ...backgroundStyle, ...baseStyle };

  return (
    <>
      <main id="main-page" style={mainStyle}>
        {showUnderConstruction ? (
          <UnderConstruction />
        ) : (
          <ContextProvider>
            <Header />
            <About />
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/archive" element={<Archive />} />
              </Routes>
            </BrowserRouter>
          </ContextProvider>
        )}
      </main>
      <Footer />
    </>
  );
};

export default App;
